.bodylogin{
    background-image: url('../../assets/imgs/background-login.jpg');
    height: 100vh; /* Note a medida */
    display: flex;
    border: 0;
}

.form_login {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    margin-top: 200px;
    border-width:  1x;
    border-radius: 5px;
    border-color: #4B8B33; 
    border-style: solid;
    flex-direction: column;
    width: 470px;
    height: 400px;
    opacity:0.98px;
}

.logo-form-login{
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: center;
}

.logo-bora{
    padding-top: 20px;
    padding-left: 20px;
    position: absolute;
    bottom: 0;
}